import React from 'react'
import cx from 'classnames'
//
const SingleNotice = ({
  className, isShown, link, imageUrl, videoUrl, onClose,
}) => (!isShown ? null : (
  <div className={cx(
    'fixed top-1/2 transform -translate-y-1/2 border-1 border-[#000] z-30',
    'mmd:left-1/2 mmd:-translate-x-1/2',
    className,
  )}
  >
    <div className={cx('relative flex flex-col items-stretch overflow-visible', 'w-[80vw]', {
      'xl:w-[40vw]': !imageUrl,
      'xl:w-[30vw]': imageUrl,
    })}
    >
      {imageUrl ? (
        // 이미지 타입일 경우 - 현재와 동일하게 처리
        link ? (
          <a href={link}>
            <img src={imageUrl} alt="공지사항" style={{ width: '100%', margin: 0, padding: 0 }} />
          </a>
        ) : (
          <img src={imageUrl} alt="공지사항" />
        )
      ) : (
        // 비디오 타입일 경우
        <div className="relative">
          <video
            src={videoUrl}
            className="popup-vid"
            controls
            muted
            autoPlay
            playsInline
            style={{
              width: '100%',
              aspectRatio: '16/9',
              margin: 0,
              padding: 0,
            }}
          />
          {link && (
            <a
              href={link}
              className={cx(
                'absolute flex items-center justify-center w-[20%] lg:w-[16%] aspect-btn bottom-[65px] right-[2%] bg-[#000]/80 text-white',
                'rounded hover:bg-[#000]/90 transition-all',
                'text-[8px] sm:text-[10px] lg:text-[12px]',
              )}
            >
              자세히 보기
            </a>
          )}
        </div>
      )}
      <div className="flex border-t border-[#aaa]">
        <button
          className={cx(
            'cta-button flex-1 bg-white border-r border-[#aaa] rounded-none',
            '!px-4 text-[10px] lg:text-[14px] xlg:text-[16px]',
          )}
          onClick={() => {
            onClose(true)
          }}
          style={{ marginBottom: 0 }}
        >
          오늘 하루 보지 않기
        </button>
        <button
          className={cx(
            'cta-button flex-1 bg-white rounded-none',
            '!px-4 text-[10px] lg:text-[14px] xlg:text-[16px]',
          )}
          onClick={() => {
            onClose()
          }}
          style={{ marginBottom: 0 }}
        >
          닫기
        </button>
      </div>
    </div>
  </div>
))

export default SingleNotice
