import React from 'react'
import moment from 'moment'

import { getNoticeData } from '../../../services/api'
import SingleNotice from './singleNotice'
//
const getStorageKey = (index) => `LeftNotice_${index}`
//
const NoticeSet = () => {
  const [shownStates, setShownStates] = React.useState([])
  const [notices, setNotices] = React.useState([])

  React.useEffect(() => {
    (async () => {
      const result = await getNoticeData()
      if (result?.data) {
        const activeNotices = result.data.filter((notice) => notice.active)
        // 각 공지사항별로 보여줄지 여부 결정
        const newShownStates = activeNotices.map((_, index) => {
          const date = localStorage.getItem(getStorageKey(index))
          return !date || !moment(date).isSame(moment(), 'D')
        })

        setNotices(activeNotices)
        setShownStates(newShownStates)
      }
    })()
  }, [])

  const onClose = (index, noMoreToday = false) => {
    if (noMoreToday) {
      localStorage.setItem(getStorageKey(index), moment().format('YYYY-MM-DD'))
    }
    console.log(index)
    setShownStates((prev) => {
      const newStates = [...prev]
      newStates[index] = false
      console.log(newStates)
      return newStates
    })
  }

  const shownCount = notices.filter((_, index) => shownStates[index]).length
  if (notices.length === 0) return null
  return (
    <>
      {notices.map((notice, index) => (
        <SingleNotice
          key={index}
          className={
            shownCount === 2
              ? index === 0
                ? 'md:left-[8vw]'
                : 'md:right-[8vw]'
              : 'md:left-1/2 md:-translate-x-1/2'
          }
          isShown={shownStates[index]}
          link={notice.link}
          imageUrl={notice.imageUrl}
          videoUrl={notice.videoUrl}
          onClose={(noMoreToday) => onClose(index, noMoreToday)}
        />
      ))}
    </>
  )
}

export default NoticeSet
